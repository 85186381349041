import BigNumber from 'bignumber.js';

import { TaskInfo } from 'api';

export enum TaskType {
  ReadOnly,
  Checkable,
  CreateTwitterPost,
}

export class TaskModel {
  constructor(dto: TaskInfo) {
    this.id = dto.id;
    this.code = dto.code;
    this.name = dto.name;
    this.description = dto.description;
    this.firstXp = BigNumber(dto.firstXp);
    this.nextXp = BigNumber(dto.nextXp);
    this.logoUrl = dto.logoUrl;
    this.stageId = dto.stageId;

    this.type = getTaskTypeFromDto(dto);

    this.primaryLink =
      dto.links.find((x) => x.linkType === 'website')?.link ??
      dto.links.length > 0
        ? dto.links[0].link
        : undefined;

    this.additionalInfoHtml = dto.info ?? undefined;

    this.isBlue = dto.isBlue;

    this.requiredFriendsCnt = dto.reqFrCnt ?? undefined;
  }

  id: number;
  code: string;

  name: string;
  description: string;

  type: TaskType;

  firstXp: BigNumber;
  nextXp: BigNumber;

  stageId: number;

  logoUrl?: string | null;

  additionalInfoHtml?: string;

  primaryLink?: string;

  isBlue?: boolean;

  requiredFriendsCnt?: number;
}

const getTaskTypeFromDto = (dto: TaskInfo) => {
  if (dto.code === 'Social / Twitter') {
    return TaskType.CreateTwitterPost;
  }

  if (dto.code.includes('Social')) {
    return TaskType.Checkable;
  }

  return TaskType.ReadOnly;
};
