import BigNumber from 'bignumber.js';

import { UserReferralStatsResponseDto } from 'api';

interface LevelStats {
  level: string;
  count: number;
  xpGained: BigNumber;
}

export class ReferralStatsModel {
  constructor(dto: UserReferralStatsResponseDto) {
    this.refCode = dto.refCode;

    const l1Data = dto.stats.find((x) => x.lvl === 'l1');

    this.l1 = l1Data
      ? {
          level: l1Data.lvl.toUpperCase(),
          count: l1Data.referralsCnt,
          xpGained: BigNumber(l1Data.xpGained),
        }
      : { level: 'L1', count: 0, xpGained: BigNumber(0) };

    const l2Data = dto.stats.find((x) => x.lvl === 'l2');

    this.l2 = l2Data
      ? {
          level: l2Data.lvl.toUpperCase(),
          count: l2Data.referralsCnt,
          xpGained: BigNumber(l2Data.xpGained),
        }
      : { level: 'L2', count: 0, xpGained: BigNumber(0) };

    this.virtualFriends = {
      level: 'Virtual',
      count: dto.vFrCnt ?? 0,
      xpGained: BigNumber(dto.vFrXp ?? 0),
    };
  }

  refCode: string;
  l1: LevelStats;
  l2: LevelStats;
  virtualFriends: LevelStats;

  get allLevels() {
    return [this.l1, this.l2, this.virtualFriends];
  }
}
