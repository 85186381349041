import styles from './task.module.css';
import classNames from 'classnames';

import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { FaChevronDown } from 'react-icons/fa6';
import { TaskModel } from 'models';
import { XpLabel } from '../xp-label/xp-label';
import { TaskInfoHtml } from './info-html/info-html';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { TaskStore } from './task-store';
import { useBaseStores } from 'providers/base-stores-provider';
import { CheckTaskButton } from './check-task-button/check-task-button';
import { BlueTaskIcon } from '../blue-task-icon';

type Props = {
  task: TaskModel;
};
export const Task = observer(({ task }: Props) => {
  const intl = useIntl();

  const { telegramStore, venomNetworkApi, userStore } = useBaseStores();

  const taskStore = useLocalObservable(
    () => new TaskStore(task, telegramStore, userStore, venomNetworkApi)
  );

  const onInternalLinkClicked = useCallback(
    (href: string) => {
      taskStore.openLink(href);
    },
    [taskStore]
  );

  const handleTaskClick = useCallback(() => {
    taskStore.openTaskPrimaryLink();
  }, [taskStore]);

  return (
    <div className={styles.main}>
      <div
        className={classNames(styles.task_main_section_outer, {
          [styles.highlight]: taskStore.highlightTask,
        })}
        onClick={handleTaskClick}
      >
        <div className={styles.task_main_section_inner}>
          <div className={styles.task_main_section_container}>
            <div className={styles.header}>
              <div className={styles.title}>{task.name}</div>

              <div className={styles.title_info}>
                {task.isBlue && <BlueTaskIcon />}

                <XpLabel value={task.firstXp} size='sm' />
              </div>
            </div>

            {!!task.requiredFriendsCnt && (
              <div className={styles.status}>
                <span>
                  {intl.formatMessage(
                    {
                      id: 'task.friends_required',
                      defaultMessage: '{count} friends required',
                    },
                    { count: task.requiredFriendsCnt }
                  )}
                </span>
              </div>
            )}

            <div className={styles.description}>{task.description}</div>

            {taskStore.isTaskCompleted && (
              <div className={styles.completed_label}>
                {intl.formatMessage({
                  id: 'task.done',
                  defaultMessage: 'Done',
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      {taskStore.expandInfoSection && (
        <div className={styles.task_info_section}>
          <TaskInfoHtml
            task={task}
            onInternalLinkClick={onInternalLinkClicked}
          />
        </div>
      )}

      {!!task.additionalInfoHtml && (
        <button
          className={classNames(styles.task_btn, styles.expand_btn)}
          onClick={() => taskStore.toggleInfoSection()}
        >
          <span>
            {!taskStore.expandInfoSection
              ? intl.formatMessage({
                  id: 'task.more_info.show',
                  defaultMessage: 'More info',
                })
              : intl.formatMessage({
                  id: 'task.more_info.hide',
                  defaultMessage: 'Less info',
                })}
          </span>

          <FaChevronDown
            className={classNames({
              [styles.icon_rotated]: taskStore.expandInfoSection,
            })}
          />
        </button>
      )}

      <CheckTaskButton store={taskStore} />

      {taskStore.isTaskCompleted && (
        <div className={styles.task_completed_indicator} />
      )}
    </div>
  );
});
