import { VenomNetworkApi } from 'api';
import { ReferralsListSortField, RefLevel } from 'api';
import { CONFIG } from 'config';
import { makeAutoObservable, runInAction } from 'mobx';
import { ReferralModel, ReferralStatsModel, StageModel } from 'models';
import { Collection } from 'stores';

export enum FriendsTabType {
  L1 = 'L1',
  L2 = 'L2',
  ALL = 'ALL',
}

export class FriendsPageStore {
  constructor(private venomNetworkApi: VenomNetworkApi) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  private _initialized = false;
  private _currentTabType = FriendsTabType.L1;

  private _referralsCollection = new Collection<ReferralModel>({
    fetchFn: async (params) => {
      const refLvl =
        this._currentTabType !== FriendsTabType.ALL
          ? (this._currentTabType.toLowerCase() as RefLevel)
          : null;

      const result = await this.venomNetworkApi.info.postReferralsList({
        season: CONFIG.season.current,
        pagination: {
          take: params.take,
          skip: params.skip,
        },
        sorting: {
          field: ReferralsListSortField.XP,
          asc: false,
        },
        lvl: refLvl,
      });

      return {
        items: result.data.data.map((x) => new ReferralModel(x)),
      };
    },
    itemsPerPage: 10,
  });

  private _referralsStats: ReferralStatsModel | null = null;

  get initialized() {
    return this._initialized;
  }

  get currentTabType() {
    return this._currentTabType;
  }

  get referralsCollection() {
    return this._referralsCollection;
  }

  get referralsStats(): ReferralStatsModel | null {
    return this._referralsStats;
  }

  async init() {
    await Promise.all([
      this.fetchReferralStats(),
      this.referralsCollection.fetchData(),
    ]);

    runInAction(() => {
      this._initialized = true;
    });
  }

  setCurrentTab(type: FriendsTabType) {
    runInAction(() => {
      this._currentTabType = type;
    });

    this.referralsCollection.fetchData();
  }

  private async fetchReferralStats() {
    try {
      const seasonInfo = await this.venomNetworkApi.info.getInfo({
        season: CONFIG.season.current,
      });

      const stages = seasonInfo.data.stages.map((x) => new StageModel(x));

      const currentStageId =
        stages.find((x) => x.status === 'live')?.id ??
        stages[stages.length - 1]?.id ??
        0;

      const result = await this.venomNetworkApi.info.getUserReferralStats({
        season: CONFIG.season.current,
        stageId: currentStageId,
      });

      runInAction(() => {
        this._referralsStats = new ReferralStatsModel(result.data);
      });
    } catch (err) {
      console.error(err);
    }
  }
}
